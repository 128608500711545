import React from 'react';
import PropTypes from 'prop-types';

function Text({ children }) {
  return (
  <div className="bg-gray-200 py-10 md:py-20">
    <div className="max-w-4xl mx-auto">
      <div className="flex items-center px-8 lg:px-4 xl:px-8">
        <div className="p-6 md:p-10 bg-white shadow rounded">
          {children}
        </div>
      </div>
    </div>
  </div>
  );
}
Text.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Text;
