import React from 'react';

import Layout from 'src/components/layout';
import SEO from 'src/components/seo';

import Banner from 'src/components/banner';
import Footer from 'src/components/footer';

import TextSection from 'src/components/text';

function DisclaimerPage() {
  return (
    <Layout>
      <SEO
        keywords={[`TinyTots PT`, `Shruti Pradhan`]}
        title="Disclaimer"
      />
      <Banner>
        <span className="border-b-2 border-yellow">Disclaimer</span>
      </Banner>
      <TextSection>
        <div className="text-lg text-justify leading-relaxed">
          All information on TinyTots PT (website, blog, Instagram, Facebook, Pinterest or any social media platforms)
          is for educational purposes only. While I’m a licensed healthcare professional in the US,
          I’m not your littles Physical Therapist. My goal is to provide education and empower parents,
          but it does not replace a formal evaluation by a pediatric PT. I cannot give medical advice or
          individualized advice via social media or my blog. All services provided through this platform are
          educational only. If you’re concerned about your child’s development, follow your mom gut and seek
          advice from your primary healthcare provider.
        </div>
      </TextSection>
      <Footer />
    </Layout>
  );
}

export default DisclaimerPage;
